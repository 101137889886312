import { FormGroup, Input, Label } from "reactstrap";

import React from "react";

export default function SelectBox({
  label = "",
  placeholder = "",
  id = "",
  name = "",
  options = [],
  onChange = () => {},
  error = "",
  style = {},
  disabled = false,
  value = "",
}) {
  return (
    <FormGroup style={style}>
      {label && (
        <Label className="form-label" htmlFor={name}>
          {label}
        </Label>
      )}

      <Input
        type="select"
        id={id ?? name}
        name={name}
        // innerRef={innerRef}
        // invalid={invalid}
        onChange={onChange}
        disabled={disabled}
        value={value}
      >
        <option value="" selected disabled hidden>
          {placeholder}
        </option>
        {options.map((data, index) => (
          <option key={index} value={data.id}>
            {data.value}
          </option>
        ))}
      </Input>
      {error ? (
        <div className="text-danger text-capitalize">{error}</div>
      ) : null}
    </FormGroup>
  );
}
