import { lazy } from 'react'

const DashboardRoute = [

  {
    path: '/rolelist',
    component: lazy(() => import('../Container/rolelist')),
    meta: {
      authRoute: true
    }
  },


  {
    path: '/rolelist-edit/:id',
    component: lazy(() => import('../Container/rolelist-edit')),
    meta: {
      authRoute: true
    }
  },

  {
    path: '/checkbox',
    component: lazy(() => import('../Container/checkbox')),
    meta: {
      authRoute: true
    }
  }, 

]

export default DashboardRoute