import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/leads",
    component: lazy(() => import("../Container/leads")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lead-detail/:uuid",
    component: lazy(() => import("../Container/lead-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lead-edit/:id",
    component: lazy(() => import("../Container/lead-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lead-add",
    component: lazy(() => import("../Container/lead-add")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
