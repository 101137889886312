import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/projects",
    component: lazy(() => import("../Container/projects")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/project-detail/:id",
    component: lazy(() => import("../Container/project-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/projects-edit/:id",
    component: lazy(() => import("../Container/project-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/project-details/:projectId/task-edit/:id",
    component: lazy(() => import("../Container/task-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/project-add",
    component: lazy(() => import("../Container/ProjectAdd")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/review-task",
    component: lazy(() => import("../Container/review")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/review-approval/:projectId/:taskId",
    component: lazy(() => import("../Container/ApprovalReview")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/task-listing",
    component: lazy(() => import("../Container/TaskFilter")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/reports",
    component: lazy(() => import("../Container/reports")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
