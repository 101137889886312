import { useState, createContext } from 'react'
import { IntlProvider } from 'react-intl';
import { Languages } from '../services/ModuleServices';

const menuMessages = Languages;

const Context = createContext();
const defaultLang = process.env.REACT_APP_DEFAULT_LANG;

const Internationalization = ({ children }) => {
  const [locale, setLocale] = useState(defaultLang)
  const [messages, setMessages] = useState(menuMessages[defaultLang])
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={defaultLang}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { Internationalization, Context as IntlContext }
