import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/forms",
    component: lazy(() => import("../Container/forms")),
    meta: {
      authRoute: true
    }
  },


  {
    path: "/addform",
    component: lazy(() => import("../Container/add-form")),
    meta: {
      authRoute: true
    },
  },

  {
    path: "/viewform/:id",
    component: lazy(() => import("../Container/view-form")),
    meta: {
      authRoute: true
    }
  },

  {
    path: "/editform/:id",
    component: lazy(() => import("../Container/edit-form")),
    meta: {
      authRoute: true
    }
  },

];

export default DashboardRoute;
