// ** Routes Imports

import Assets from "./Assets";
import Auth from "./Auth";
import Contact from "./Contact";
import Customers from "./Customers";
import Dashboard from "./Dashboard";
import Deals from "./Deals";
import Forms from "./Forms";
import Leads from "./Leads";
import Mail from "./Mail";
import Menus from "./Menus";
import Projects from "./Projects";
import RoleList from "./Settings/SubModule/RoleLists";
import Sales from "./Sales";
import Services from "./Services";
import Settings from "./Settings";
import UserProfile from "./UserProfile";
import Users from "./Settings/SubModule/Users";
import Finance from "./Finance";
import Vendors from "./Vendors";
import Reports from "./Reports";
import CertificateTemplate from "./CertificateTemplate";

export default [
  Auth,
  Assets,
  Dashboard,
  Mail,
  Leads,
  Deals,
  Contact,
  Sales,
  Services,
  Customers,
  Settings,
  RoleList,
  Forms,
  Users,
  Projects,
  UserProfile,
  Menus,
  Finance,
  Vendors,
  Reports,
  CertificateTemplate,
];
