import { Button, CustomInput, Form, FormGroup, Input, Label } from "reactstrap";

import React from "react";
import { trans } from "../../utility";

export default function InputText({
  key,
  onBlur,
  error,
  autoComplete,
  defaultValue,
  onKeyDown,
  innerRef,
  invalid,
  onmouseover,
  onChange,
  change,
  value,
  label,
  placeholder = "",
  required = false,
  id = "",
  name,
  type = "text",
  autoFocus = false,
  list,
  disabled = false,
  style = {},
  onInput,
  checked = "",
  mandatory = false,
}) {
  let props = {};

  if (value) props.value = value;
  if (required) props.required = required;
  if (id) props.id = id && name;
  if (type) props.type = type;
  if (autoFocus) props.autoFocus = autoFocus;

  return (
    <FormGroup style={style}>
      {label && (
        <Label className="form-label" htmlFor="login-email">
          {trans(label)}{" "}
          {mandatory ? <span className="text-danger">*</span> : ""}
        </Label>
      )}

      <Input
        {...props}
        placeholder={trans(placeholder)}
        id={id || name}
        name={name}
        innerRef={innerRef}
        invalid={invalid}
        defaultValue={defaultValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        onBlur={onBlur}
        key={key}
        onMouseOver={onmouseover}
        list={list}
        disabled={disabled}
        required={required}
        onInput={onInput}
        checked={checked}
      />

      {error ? (
        <div className="text-danger text-capitalize">{error}</div>
      ) : null}
    </FormGroup>
  );
}
