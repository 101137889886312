import constUrl from "../Const";
import { request } from "../../../services/AppRequest";
import moment from "moment";

export const fetchProposal = async (id) => {
  const response = await request(`${constUrl.GET_API}/${id}`, {
    method: "GET",
  });

  return response;
};

export const addProposal = async (formData, proposal_detail, uuid) => {
  const { contact_person_id, contact_no, department_id } = formData;
  let data = {
    ...formData,
    contact_person_id: parseInt(contact_person_id),
    contact_no: parseInt(contact_no),
    department_id: parseInt(department_id),
    proposal_detail: proposal_detail,
    company_id: parseInt(formData.company_name),
    customer_proposal_status: "new",
  };

  const response = await request(`${constUrl.GET_API}`, {
    method: "POST",
    body: data,
    headers: uuid,
  });

  return response;
};

export const updateProposal = async (id, formData, proposal_detail) => {
  const { contact_person_id, contact_no, department_id } = formData;
  let data = {
    ...formData,
    contact_person_id: parseInt(contact_person_id),
    contact_no: parseInt(contact_no),
    department_id: parseInt(department_id),
    proposal_detail: proposal_detail,
    company_id: parseInt(formData.company_name),
    proposal_title: formData.proposal_title,
    tax_id: formData.tax_id,
    customer_address: formData.customer_address,
    validity: formData.validity,
    company_address: formData.company_address,
    customer_tax_id: formData.customer_tax_id,
    email: formData.email,
    valid_till: moment(formData.ValidTill).format("YYYY-MM-DD"),
    company_id: formData.company_id,
    company_name: formData.company_name,
    customer_proposal_status: formData.customer_proposal_status,
    upload: formData.upload,
    introduction: formData.introduction,
    scope_of_work: formData.scope_of_work,
    deliverables: formData.deliverables,
    payment_terms: formData.payment_terms,
    terms_conditions: formData.terms_conditions,
    disclaimer: formData.disclaimer,
    lead_id: formData.lead_id,
  };
  const response = await request(`${constUrl.GET_API}/${id}`, {
    method: "PUT",
    body: data,
  });
  return response;
};

export const deleteProposal = (id) => async () => {
  const response = await request(`${constUrl.GET_API + id}`, {
    method: "DELETE",
  });
  return response;
};
export const getSettings =
  (query = "type_in=tax_slabs,quantity_types") =>
  async () => {
    const response = await request(`/api/admin/configs/settings?${query}`, {
      method: "GET",
    });
    return response;
  };
export const getClientAssets = (query) => async () => {
  const response = await request(
    `/api/admin/client-assets?sort=ID:desc&limit=1000`,
    {
      method: "GET",
    }
  );
  return response;
};

export const updateLPO = async (id, formData) => {
  let data = {
    ...formData,
  };
  return request(`${constUrl.GET_API}/${id}`, {
    method: "PUT",
    body: data,
  });
};

export const postInvoice = async (formData) => {
  const response = await request(`${constUrl.POST_INVOICE}`, {
    method: "POST",
    body: formData,
  });

  return response;
};

export const fetchInvoiceList = async () => {
  const response = await request(`/api/admin/invoice?limit=1000`, {
    method: "GET",
  });

  return response;
};
export const fetchInvoice = async (id) => {
  const response = await request(`/api/admin/invoice/${id}`, {
    method: "GET",
  });

  return response;
};

export const fetchProject = async () => {
  const response = await request(`/api/admin/projects?limit=1000`, {
    method: "GET",
  });
  return response;
};

export const fetchContact = async (id) => {
  const response = await request(`${constUrl.CONTACT_API}/${id}`, {
    method: "GET",
  });

  return response;
};

export const fetchConfigs = (dropCategory) => async () => {
  const response = await request(`/api/admin/configs/${dropCategory}`, {
    method: "GET",
  });

  return response.data;
};

export const fetchProposals = async () => {
  const response = await request(`${constUrl.GET_API}`, {
    method: "GET",
  });

  return response;
};

export const fetchOwner = () => async () => {
  const response = await request(`/api/admin/users?limit=100`, {
    method: "GET",
  });

  return response.data;
};

export const proposalSend = async (payload) => {
  const response = await request(`${constUrl.MAIL_API}`, {
    method: "POST",
    body: payload,
  });

  return response;
};

export const fetchProposalSettings = async () => {
  const response = await request(
    `/api/admin/configs/settings?type_in=proposal_settings`,
    {
      method: "GET",
    }
  );

  return response;
};

export const getProjectList = () => async () => {
  const response = await request(`/api/admin/projects?limit=1000`, {
    method: "GET",
  });
  return response;
};

export const fetchClientAssets = async () => {
  const response = await request(
    `/api/admin/client-assets?sort=ID:desc&limit=1000`,
    {
      method: "GET",
    }
  );
  return response;
};
