import Modules from "../Modules";

const TemplateTitle = "";
const DefaultRoute = "";

let Routes = [];
let Languages = {};

let langName = [];
Modules.forEach((e) => {
  Routes.push(...e.Routes);
  langName.push(...Object.keys(e.Translation));
});

langName = [...new Set(langName)];
langName.forEach((e) => {
  for (let i = 0; i < Modules.length; i++) {
    const element = Modules[i];
    Languages[e] = { ...Languages[e], ...element.Translation[e] };
  }
});

export { DefaultRoute, TemplateTitle, Routes, Languages };
