import * as Icon from "react-feather";
import { FormGroup, Input, Label } from "reactstrap";

import { AppTextBox } from "../Components/Forms";
import CheckBoxRadio from "../Modules/Forms/Components/CheckBoxRadio";
const FormSelectBox = (props) => {
  return (
    <FormGroup>
      <Input {...props}>
        {props?.options?.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </Input>
    </FormGroup>
  );
};
const FileInput = (props) => {
  if (props.value && props.disabled) {
    return (
      <img
        id="dynamicForm"
        className="dynamic-form-image-preview"
        src={props.value}
        alt="your image"
      />
    );
  }
  return <AppTextBox {...props} />;
};
export const components = [
  {
    label: "Text Input",
    icon: () => <Icon.Type color="#849D9D" size={20} />,
    componentRender: (props) => <AppTextBox {...props} value={"some value"} />,
    component: "input",
    type: "text",
    col: 1,
    key: "text-input",
    hasOptions: false,
  },
  {
    label: "Select box",
    icon: () => <Icon.ChevronsDown color="#849D9D" size={20} />,
    componentRender: (props) => <FormSelectBox {...props} />,
    component: "input",
    type: "select",
    col: 1,
    key: "select-box",
    hasOptions: true,
  },
  {
    label: "Text Area",
    icon: () => <Icon.Type color="#849D9D" size={20} />,
    componentRender: (props) => <AppTextBox {...props} />,
    col: 2,
    component: "input",
    type: "textarea",
    key: "text-area",
    hasOptions: false,
  },
  {
    label: "File",
    icon: () => <Icon.File color="#849D9D" size={20} />,
    componentRender: (props) => <FileInput {...props} />,
    col: 2,
    component: "input",
    type: "file",
    key: "file",
    hasOptions: false,
  },
  {
    label: "Radio",
    icon: () => <Icon.Circle color="#849D9D" size={20} />,
    componentRender: (props) => <CheckBoxRadio {...props} />,
    col: 2,
    component: "input",
    type: "radio",
    key: "radio",
    hasOptions: true,
    isCheckbox: true,
  },
  {
    label: "CheckBox",
    icon: () => <Icon.CheckSquare color="#849D9D" size={20} />,
    componentRender: (props) => <CheckBoxRadio {...props} />,
    col: 2,
    component: "input",
    type: "checkbox",
    key: "checkbox",
    hasOptions: true,
    isCheckbox: true,
  },
];
export const defaultLayouts = [
  {
    key: "singleColumn",
    className: "col-md-12",
    label: "Single Column",
    parentClassName: "col-md-4",
    items: [1],
    itemWidth: "100%",
  },
  {
    key: "twoColumn",
    className: "col-md-6",
    label: "Two Column",
    parentClassName: "col-md-4",
    items: [1, 2],
    itemWidth: "45%",
  },
  {
    key: "threeColumn",
    className: "col-md-4",
    label: "Three Column",
    parentClassName: "col-md-4",
    items: [1, 2, 3],
    itemWidth: "30%",
  },
];
