import Auth from "../Modules/Auth";
import axios from "axios";
// create axios instance for api calls
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const request = (url, { method = "GET", headers, body = "" } = "") => {
  method = method.toLowerCase();
  return new Promise((resolve, reject) => {
    var access_token = localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : sessionStorage.getItem("access_token");
    var uuid = headers ? headers : "na";
    var myHeaders = new Headers();
    myHeaders.append("X-From-Micro-Service", "leads");
    myHeaders.append("X-Uuid", uuid);
    myHeaders.append("X-Tenant-Id", "centresource");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);
    method = method.toUpperCase();
    var raw = JSON.stringify(body);
    var requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };

    if (body) requestOptions.body = raw;

    url = process.env.REACT_APP_API + url;

    fetch(url, requestOptions)
      // .then(response => response.json())
      .then(async (result) => {
        if (result.status === 401) {
          return reject({
            status: result.status,
            response: await result.json(),
          });
        }
        return resolve(await result.json());
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};

// export PUT api axios request with auth token
export const putRequest = (
  url,
  { method = "PUT", headers, body = "" } = ""
) => {
  method = method.toLowerCase();
  return new Promise((resolve, reject) => {
    var access_token = localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : sessionStorage.getItem("access_token");
    var myHeaders = new Headers();
    myHeaders.append("X-From-Micro-Service", "leads");
    myHeaders.append("X-Uuid", "na");
    myHeaders.append("X-Tenant-Id", "centresource");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + access_token);
    method = method.toUpperCase();
    console.log(myHeaders);
    axios
      .put(`${process.env.REACT_APP_API}/${url}`, body, { headers: myHeaders })
      .then((result) => {
        if (result.status === 401) {
          return reject({
            status: result.status,
            response: result,
          });
        }
        return resolve(result.json());
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};
