import React from 'react';
import { Button } from 'reactstrap';
import { trans } from '../../utility';

export default function AppButton({ onClick, label = "", className = "", color = "primary", type = "button" }) {

    let props = {};

    if (onClick) props.onClick = onClick;

    return (
        <Button.Ripple {...props} color={color} className={className} block type={type}>
            {trans(label)}
        </Button.Ripple>
    )
}