import { lazy } from 'react'

const DashboardRoute = [
  {
    path: '/login',
    component: lazy(() => import('../Container/login')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/signup',
    component: lazy(() => import('../Container/signup')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/forgot-password',
    component: lazy(() => import('../Container/forgot-password')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  }
]

export default DashboardRoute