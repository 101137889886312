import { createSlice } from "@reduxjs/toolkit";
import { setStorageItem } from "../../../utility";

const initialState = {
  userData: {},
  token: "",
  loginFormData: {
    email: "",
    password: "",
    isChecked: false,
    errors: { email: "", password: "" },
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.userData = action.payload;
      state.token = action.payload.token;
      setStorageItem("access_token", action.payload.token);
      setStorageItem("userData", JSON.stringify(action.payload.user));
    },
    logout: (state, action) => {
      state.userData = {};
      state.token = "";
    },
    setLoginFormData: (state, action) => {
      // alert(action.payload.value);
      state.loginFormData[action.payload.key] = action.payload.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setLoginFormData } = authSlice.actions;

export default authSlice.reducer;
