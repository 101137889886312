import { lazy } from 'react'

const DashboardRoute = [
  {
    path: '/mail',
    component: lazy(() => import('../Container/mail')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/open-mail',
    component: lazy(() => import('../Container/open-mail')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/openmail-added',
    component: lazy(() => import('../Container/openmail-added')),
    meta: {
      authRoute: true
    }
  },

]

export default DashboardRoute