import { lazy } from 'react'

const DashboardRoute = [

  {
    path: '/settings',
    component: lazy(() => import('../Container/settings')),
    meta: {
      authRoute: true
    }
  },

]

export default DashboardRoute