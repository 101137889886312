import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/services",
    component: lazy(() => import("../Container/services")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/services-detail/:id",
    component: lazy(() => import("../Container/services-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/services-edit/:id",
    component: lazy(() => import("../Container/services-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/products-edit/:id",
    component: lazy(() => import("../Container/products-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/services-sub-detail/:id",
    component: lazy(() => import("../Container/services-sub-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/services-sub-edit/:id",
    component: lazy(() => import("../Container/services-sub-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/services-add",
    component: lazy(() => import("../Container/services-add-form")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/services-sub-add/:id",
    component: lazy(() => import("../Container/services-sub-add-form")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
