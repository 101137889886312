import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/certificate-template",
    component: lazy(() => import("../Container/CertificateTemplate")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/template-add",
    component: lazy(() => import("../Container/TemplateAddForm")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/template-edit/:id",
    component: lazy(() => import("../Container/TemplateEditForm")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/template-show/:id",
    component: lazy(() => import("../Container/TemplateShow")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
