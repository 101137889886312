import { lazy } from 'react'

const DashboardRoute = [
  {
    path: '/dashboard',
    component: lazy(() => import('../Container')),
    meta: {
      authRoute: true
    }
  },
]

export default DashboardRoute