import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/menu/create",
    component: lazy(() => import("../Container/create")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/menu",
    component: lazy(() => import("../Container/list")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
