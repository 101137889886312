import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/vendors",
    component: lazy(() => import("../Container/Vendors")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-add",
    component: lazy(() => import("../Container/vendor-add-form")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-edit/:id",
    component: lazy(() => import("../Container/vendor-edit-form")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-detail/:id",
    component: lazy(() => import("../Container/vendor-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-lpo",
    component: lazy(() => import("../Container/VendorLpo")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-lpo-add",
    component: lazy(() => import("../Container/vendor-lpo")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/lpo-details/:id",
    component: lazy(() => import("../Container/lpo-details")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
