import { FormattedMessage, useIntl } from "react-intl";
import { components, defaultLayouts } from "../Constants/Forms-Component";
import axios from "axios";
import moment from "moment";

export function lang(name) {
  return <FormattedMessage id={name} />;
}

export function trans(transKey, addModule = true, options = {}) {
  if (!transKey) return "";

  try {
    const { formatMessage } = useIntl();
    if (addModule) return formatMessage({ id: `${transKey}` }, options);

    return formatMessage({ id: `${transKey}` }, options);
  } catch (error) {
    return transKey;
  }
}

export function getStorageItem(key) {
  return localStorage.getItem(key)
    ? localStorage.getItem(key)
    : sessionStorage.getItem(key);
}

export function setStorageItem(key, data, isChecked) {
  //alert(isChecked);
  isChecked
    ? window.localStorage.setItem(key, data)
    : window.sessionStorage.setItem(key, data);
}
export const formatFormData = (data) => {
  data.data.rows = data?.data?.rows?.map((row) => {
    row.layoutFullObject = defaultLayouts.find(
      (layout) => layout.key === row.layout
    );
    row.noOfColumns = row.fields.length;
    row.fields = row.fields.map((field) => {
      const { hasOptions, icon, isCheckbox, componentRender } = components.find(
        (component) => component.key === field.key
      );
      field.componentRender = componentRender;
      field.hasOptions = hasOptions;
      field.icon = icon;
      field.isCheckbox = isCheckbox;

      return field;
    });
    return row;
  });
  return data;
};

export const handleError = (error) => {
  console.log(error);
  let errorMessage = "";
  if (error?.message?.toLowerCase()?.includes("validation")) {
    let validationErrors = error.error;
    errorMessage = Object.keys(validationErrors).map((key) => {
      if (typeof validationErrors[key] === "string") {
        if (validationErrors[key].includes(key)) {
          return validationErrors[key];
        }
        return `${key} ${validationErrors[key]}`;
      } else {
        return `${key} is required or invalid`;
      }
    });
    return errorMessage[0] ?? "Something went wrong";
  } else return error.error ?? "Something went wrong";
};

export const mediaUpload = async (
  file,
  recordId = "",
  serviceName = "",
  slug = ""
) => {
  const access_token = localStorage.getItem("access_token")
    ? localStorage.getItem("access_token")
    : sessionStorage.getItem("access_token");
  const myHeaders = {
    "X-From-Micro-Service": "leads",
    "X-Uuid": "na",
    "X-Tenant-Id": "centresource",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + access_token,
  };

  const formData = new FormData();
  formData.append("files", file);

  const response = await axios({
    method: "post",
    url:
      process.env.REACT_APP_API +
      `/api/admin/media/upload?record_id=${recordId}&service_name=${serviceName}&slug=${slug}`,
    data: formData,
    headers: myHeaders,
  });

  return response;
};
export const roundNum = (num) => {
  num = parseFloat(num);
  return Math.round((num + Number.EPSILON) * 100) / 100;
};
const makeThreeDigit = (value) => {
  const lenght = value.toString().length;
  if (lenght == 1) return `00${value}`;
  if (lenght == 2) return `0${value}`;
  return value;
};

export const generateCertificateNumber = (
  projectId = "",
  companyName = "",
  type = "",
  taskId = ""
) => {
  const companyAbbr = "TIS";
  const year = moment().format("YYYY");
  return `${companyAbbr}-JN-${year}-${makeThreeDigit(
    projectId
  )}/${companyName.substring(0, 4)}-${type}-${makeThreeDigit(taskId)}`;
};
