import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/finance",
    component: lazy(() => import("../Container/finance")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/project-finance/:id",
    component: lazy(() => import("../Container/project-finance")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/add-receipt",
    component: lazy(() => import("../Container/add-receipt")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/add-expenses",
    component: lazy(() => import("../Container/add-expenses")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-payment",
    component: lazy(() => import("../Container/vendor-payment")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/loss-of-poor-quality",
    component: lazy(() => import("../Container/loss-of-poor-quality")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/finance-details/:id",
    component: lazy(() => import("../Container/finance-details")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/finance-details/:id",
    component: lazy(() => import("../Container/finance-details")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/vendor-payment-list",
    component: lazy(() => import("../Container/vendor-payment-list")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/receipt-list",
    component: lazy(() => import("../Container/receipt-list")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/expense-list",
    component: lazy(() => import("../Container/expense-list")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/loss-of-poor-quality-list",
    component: lazy(() => import("../Container/loss-of-poor-quality-list")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
