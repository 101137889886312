import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { proposalSend } from "../Method";
import "@styles/base/pages/page-auth.scss";

const MailView = () => {
  const [mailState, setMailState] = useState({ body: "", subject: "" });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setMailState({ ...mailState, [name]: value });
  };
  const { id } = useParams();
  const history = useHistory();
  const handleSubmit = async () => {
    await proposalSend({ ...mailState, proposal_id: parseFloat(id) })
      .then((res) => {
        toast.success("Email send Successfully");
        history.push(`/sales-proposals/${id}`);
      })
      .catch((error) => {
        toast.error(error.message ?? "Something went wrong");
      });
  };
  return (
    <div className="auth-wrapper auth-v1 mt-1 leads">
      <div className="auth-inner bg-white mx-0 mx-sm-1">
        <div className="py-2">
          <div className="d-block d-sm-flex px-2 justify-content-between">
            <div className="selct-leads col-12 col-sm-6 p-0">
              <FormGroup>
                <Label htmlFor="basicInput">Subject</Label>
                <Input
                  type="text"
                  id="subject"
                  placeholder="Subject"
                  onChange={handleInput}
                  name="subject"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="basicInput">Body</Label>
                <Input
                  type="textarea"
                  id="body"
                  placeholder="Body"
                  onChange={handleInput}
                  name="body"
                />
              </FormGroup>
              <div className="d-flex mt-5 pb-5">
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  className="width-150 mr-3"
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MailView;
