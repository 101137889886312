import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/assets",
    component: lazy(() => import("../Container/assets")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/assets-bkb",
    component: lazy(() => import("../Container/assets-bkb")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/assets-detail/:id",
    component: lazy(() => import("../Container/assets-detail")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/assets-edit/:id",
    component: lazy(() => import("../Container/assets-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/equipment-edit/:id",
    component: lazy(() => import("../Container/equipment-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/asset-add",
    component: lazy(() => import("../Container/asset-deal-add")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/equipment-add",
    component: lazy(() => import("../Container/equipment-add")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/equipment-detail/:id",
    component: lazy(() => import("../Container/equipment-detail")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
