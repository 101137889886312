import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/user-profile",
    component: lazy(() => import("../Container/user-profile")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/user-profile-edit/:id",
    component: lazy(() => import("../Container/user-profile-edit")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
