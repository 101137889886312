const CheckBoxRadio = ({ type = "checkbox", options = [] }) => {
  return (
    <form className="form-inline p-1">
      {options.map((option, index) => (
        <div class="form-check form-check-inline" key={index}>
          <input
            class="form-check-input"
            type={type}
            id={index}
            value={option.value}
            disabled
            checked={option.checked ?? false}
          />
          <label class="form-check-label" for={index}>
            {option.label}
          </label>
        </div>
      ))}
    </form>
  );
};
export default CheckBoxRadio;
