import { lazy } from "react";
import { MailView } from "../Container/mail-view";

const DashboardRoute = [
  {
    path: "/sales",
    component: lazy(() => import("../Container/sales")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/sales-proposals/:id",
    component: lazy(() => import("../Container/sales-proposals")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/sales-invoice/:id",
    component: lazy(() => import("../Container/sales-invoice")),
    meta: {
      authRoute: true,
    },
  },

  {
    path: "/proposals",
    component: lazy(() => import("../Container/proposals")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/proposal-edit/:id",
    component: lazy(() => import("../Container/proposal-edit")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/proposal-add",
    component: lazy(() => import("../Container/proposal-add-deal")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/send-invoice/:id",
    component: lazy(() => import("../Container/send-invoice")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/invoices",
    component: lazy(() => import("../Container/Invoices")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/email-send/:id",
    component: lazy(() => import("../Container/mail-view")),

    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
