import { lazy } from "react";

const DashboardRoute = [
  {
    path: "/certificates-reports",
    component: lazy(() => import("../Container/Reports")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/certificates-reports-filter",
    component: lazy(() => import("../Container/ReportsFilter")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/certificates",
    component: lazy(() => import("../Container/Certificates")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/certificates-report-test",
    component: lazy(() => import("../Container/ReportsFilterTest")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/asset-report/:id",
    component: lazy(() => import("../Container/AssetReport")),
    meta: {
      authRoute: true,
    },
  },
];

export default DashboardRoute;
